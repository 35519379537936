import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getEventByExpositorId, postIn, postNotes } from './actions'
import { Typography, TextField, Button } from '@material-ui/core';
import QrReader from 'react-qr-reader';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useSnackbar } from 'notistack';
import SendIcon from '@material-ui/icons/Send';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { pink } from '@material-ui/core/colors';

const EntradaQRExpositores = props => {

    const { width } = useWindowDimensions();
    const { className, ...rest } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    //const classes = useStyles();
    const [result, setResult] = useState("No result");
    const [attendeeName, setAttendeeName] = useState("Por favor, acerque el codigo QR dentro del cuadro rojo");
    const [attendee, setAttende] = useState({});
    const [event, setEvent] = useState({});
    const [notes, setNotes] = useState("");

    const handleScan = data => {
        if (data) {
            if (data != result) {
                setResult(data);

                var event_id = event.event_id;

                //if (data.match(/^[0-9a-fA-F]{24}$/)) {
                var attendee_guid = data;

                postIn(event_id, attendee_guid).
                    then(attendee => {
                        if (attendee.data != null && attendee.data !== undefined) {
                            console.log(attendee.data);
                            setAttende({ nombre: attendee.data.nombres, apellidos: attendee.data.apellidos, nombreEmpresa: attendee.data.nombreEmpresa, cargo: attendee.data.cargo, categoria: attendee.data.categoria, attendee_id: attendee.data._id, email: attendee.data.email })
                            let nombre = attendee.data.nombres + " " + attendee.data.apellidos;
                            setAttendeeName(nombre + " 👏");
                            setNotes("");
                            //setAttendeeName("Bienvenido " + nombre + "/ Empresa " + attendee.data.nombreEmpresa + "/  Cargo " + attendee.data.cargo + "/ " + attendee.data.categoria + " 👏");
                            //sendMessage(event_id, "in");
                        }
                        else {
                            setAttende({});
                            setAttendeeName("El usuario no esta registrado en el evento 😔");
                        }
                    }).
                    catch(err => {
                        console.log(err);
                        setAttendeeName("Error! no fue posible procesar la entrada 🛑");
                    })
            }
        }
    }


    const handleError = err => {
        console.error(err)
    }


    const handleChange = event => {
        setNotes(event.target.value);
    }

    const handleSaveNotes = () => {

        if (notes.trim().length > 0) {
            if (attendee.attendee_id !== undefined) {
                const notas = { notas: notes }
                postNotes(event.event_id, attendee.attendee_id, notas)
                    .then(() => { enqueueSnackbar("Las notas se guardaron exitosamente", { variant: 'success' }); })
                    .catch(err => {
                        console.log(err);
                        enqueueSnackbar("Algo salió mal. Por favor, inténtelo de nuevo más tarde", { variant: 'error' });
                    });
            }
            else {
                enqueueSnackbar("Es necesario leer el codigo del invitado para poder guardar notas", { variant: 'error' });
            }
        }
    }

    useEffect(() => {

        var arrayDeCadenas = window.location.pathname.split('/');
        var expositorId = arrayDeCadenas[arrayDeCadenas.length - 1];

        getEventByExpositorId(expositorId)
            .then(event => {
                setEvent({ name: event.data[0].name, event_id: event.data[0]._id });
            })
            .catch(err => {
                console.log(err);
            })
    }, []);


    const previewStyle = {
        width: (width < 768) ? '100%' : '40%'
    }

    return (
        <div>
            <br />
            <Typography variant="h3">
                {event.name}
            </Typography>
            <br></br>
            <Typography variant="h3">
                {attendeeName}
            </Typography>
            {
                attendee.nombre !== undefined ?
                    <>
                        <Typography variant="h4">
                            Empresa: {attendee.nombreEmpresa}
                        </Typography>
                        <Typography variant="h4">
                            Cargo: {attendee.cargo}
                        </Typography>
                        <Typography variant="h5">
                            {attendee.categoria}
                        </Typography>
                    </> : <></>
            }
            <div>
                <QrReader
                    delay={200}
                    onError={handleError}
                    onScan={handleScan}
                    style={previewStyle}
                />
                <TextField
                    fullWidth
                    autoFocus
                    helperText=""
                    label="Notas"
                    margin="dense"
                    name="name"
                    multiline
                    rows={2}
                    variant="outlined"
                    value={notes}
                    onChange={handleChange}
                />
                &nbsp;&nbsp;
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={handleSaveNotes}
                    endIcon={<NoteAddIcon/>}
                    >
                    Guardar Notas
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    target="_top"
                    rel="noopener noreferrer"
                    href={`mailto:`+attendee.email}
                    endIcon={<SendIcon />}
                >
                    Enviar correo
                </Button>
            </div>
        </div>
    );
};

EntradaQRExpositores.prototype = {
    className: PropTypes.string
};
export default EntradaQRExpositores;

