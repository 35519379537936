import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import DataGrid from './DataGrid'
import axios from "axios";
import FormData from "form-data";
import { useHistory, Link } from "react-router-dom";
import { getEventById, getPreInvitadosByEventId } from '../RealTimeDashboard/actions'
import { useSnackbar } from 'notistack';

import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Grid,
    Button,
    TextField,
    Typography
} from '@material-ui/core';
import { EventBusyOutlined } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    root: {}
}));

const EventosAdmin = props => {
    const { className, ...rest } = props;

    const classes = useStyles();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const history = useHistory();

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileName, setFileName] = useState("");
    const [eventId, setEventId] = useState("");
    const [errores, setError] = useState("");
    const [data, setData] = useState();
    const [textoSubmitButton, setTextoSubmitButton] = useState("Crear Evento");

    const [values, setValues] = useState({
        name: '',
        occupancy: 0,
        eventDate: '',
        eventTime: '',
        eventAddress: ''
    });


    useEffect(() => {
        var arrayDeCadenas = window.location.pathname.split('/');

        if (arrayDeCadenas.length > 3) {
            var eventId = arrayDeCadenas[arrayDeCadenas.length - 1];

            setTextoSubmitButton("Actualiza Evento");
            setSelectedFiles([]);
            setFileName("");

            setEventId(eventId);

            getEventById(eventId)
                .then(event => {
                    setValues(
                        {
                            name: event.data.name,
                            occupancy: event.data.occupancy,
                            eventDate: new Date(event.data.eventDate).toISOString().slice(0, 10),
                            eventTime: event.data.eventTime,
                            eventAddress: event.data.eventAddress,
                        });
                })
                .then(() => {
                    getPreInvitadosByEventId(eventId).
                        then(preinvitados => {

                            const dataArray = preinvitados.data.map(invitado => {
                                return [
                                    invitado.nombre,
                                    invitado.apellido,
                                    invitado.empresa,
                                    invitado.email,
                                    invitado.celular,
                                    invitado.envio_invitacion = ((invitado.envio_invitacion === true) ? 'Si' : 'No'),
                                    invitado.envio_invitacion_fecha,
                                    invitado.confirmacion_invitacion = ((invitado.confirmacion_invitacion === true) ? 'Si' : 'No'),
                                    invitado.confirmacion_invitacion_fecha,
                                ]
                            })

                            //console.log(dataArray);
                            setData(dataArray);
                        })
                }
                )
                .catch(err => {
                    enqueueSnackbar("No fue posible cargar la informacion del evento",  {variant: 'error'});
                })
        }
        else {
            setValues(
                {
                    ...values,
                    eventDate: new Date().toISOString().slice(0, 10),
                    eventTime: '10:00',
                });
        }
    }, [eventId]);

    const handleChange = event => {
        //console.log("change");
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleFileNameChange = event => {
        //console.log(event.target.files[0]);
        setSelectedFiles(event.target.files);
        setFileName(event.target.files[0].name);
    };

    const submitHandler = (e) => {

        e.preventDefault(); //prevent the form from submitting
        let formData = new FormData();

        formData.append("Nombre", values.name);
        formData.append("CapacidadMaxima", values.occupancy)
        formData.append("Fecha", values.eventDate);
        formData.append("Hora", values.eventTime);
        formData.append("Direccion", values.eventAddress);
        formData.append("file", selectedFiles[0]);
        formData.append("eventId", eventId);


        //Clear the error message
        setError("");
        axios
            .post("/api/files/upload_file", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            .then(response => {
                //console.log(response.data.eventId);
                setEventId(response.data.eventId);
                if (eventId === "")
                    history.push("/eventos/admin/" + response.data.eventId);
                else
                    {
                        getPreInvitadosByEventId(eventId).
                        then(preinvitados => {

                            const dataArray = preinvitados.data.map(invitado => {
                                return [
                                    invitado.nombre,
                                    invitado.apellido,
                                    invitado.empresa,
                                    invitado.email,
                                    invitado.celular,
                                    invitado.envio_invitacion = ((invitado.envio_invitacion === true) ? 'Si' : 'No'),
                                    invitado.envio_invitacion_fecha,
                                    invitado.confirmacion_invitacion = ((invitado.confirmacion_invitacion === true) ? 'Si' : 'No'),
                                    invitado.confirmacion_invitacion_fecha,
                                ]
                            })
                            setData(dataArray);
                        })
                    }
            }).
            then(message=> {
                if (eventId === "")
                    enqueueSnackbar("El evento fue dado de alta satisfactoriamente",  {variant: 'success'});
                else
                    enqueueSnackbar("La actualizacion del evento fue exitosa",  {variant: 'success'});
            })
            .catch((error) => {
                
                let errorMessage = "";

                if (error.response)
                {
                    if (error.response.data.includes("FILE_MISSING") && eventId !== "")
                        errorMessage = "Por favor seleccione el archivo antes de Crear el evento!";
                    else
                        errorMessage = error.response.data;
                }

                setError(errorMessage);
                enqueueSnackbar(errorMessage,  {variant: 'error'});
            }).
            catch (error=> {
                console.log(error);
                enqueueSnackbar("Algo salió mal. Por favor, inténtelo de nuevo más tarde",  {variant: 'error'});
            })

    };

    const handleSubmitInvitation = (e) => {

        e.preventDefault(); //prevent the form from submitting

        if (data.length>0) {
        axios
            .get("/api/files/submitInvitation2/" + eventId)
            .then(response => {
                enqueueSnackbar("Las invitaciones fueron enviadas a los invitados satisfactoraimente",  {variant: 'success'});
            })
            .then(response=> getPreInvitadosByEventId(eventId))
            .then(preinvitados => {

                const dataArray = preinvitados.data.map(invitado => {
                    return [
                        invitado.nombre,
                        invitado.apellido,
                        invitado.empresa,
                        invitado.email,
                        invitado.celular,
                        invitado.envio_invitacion = ((invitado.envio_invitacion === true) ? 'Si' : 'No'),
                        invitado.envio_invitacion_fecha,
                        invitado.confirmacion_invitacion = ((invitado.confirmacion_invitacion === true) ? 'Si' : 'No'),
                        invitado.confirmacion_invitacion_fecha,
                    ]
                })
                setData(dataArray);
            })                      
            .catch(err => { 
                console.log("Error sending emails" + err); 
                enqueueSnackbar("Ocurrio un error al momento de enviar las invitaciones a los invitados, intente mas tarde por favor",  {variant: 'error'});
            })
        }
        else
        {
            enqueueSnackbar("No hay invitados registrados al evento aun, favor de registrarlos desde el archivo de Excel",  {variant: 'warning'});
        }
    }

    const renderGrid = () => {
        if (data !== undefined) {
            return <DataGrid data={data} />;
        }
    }

    const renderBotonEnvioEmails = () => {
        if (eventId !== "")
            return <Button color="primary" variant="contained" onClick={handleSubmitInvitation}> 3) Enviar invitacion</Button>;
    }



    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <form
                autoComplete="off"
                onSubmit={submitHandler}
            >
                <CardHeader
                    subheader="1) Edite la informacion del evento"
                    title="Evento"
                />
                <Divider />
                <CardContent>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                autoFocus
                                helperText="Por favor especifique el nombre del evento"
                                label="Nombre del Evento"
                                margin="dense"
                                name="name"
                                onChange={handleChange}
                                required
                                value={values.name}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Cantidad maxima de invitados"
                                margin="dense"
                                name="occupancy"
                                onChange={handleChange}
                                required
                                requiredtype="number"
                                value={values.occupancy}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Fecha del evento"
                                margin="dense"
                                name="eventDate"
                                onChange={handleChange}
                                type="date"
                                required
                                value={values.eventDate}
                                variant="outlined"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Hora del evento"
                                margin="dense"
                                name="eventTime"
                                onChange={handleChange}
                                type="time"
                                required
                                value={values.eventTime}
                                variant="outlined"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText="Por favor especifique la direccion donde se realizara el evento"
                                label="Direccion del Evento"
                                margin="dense"
                                name="eventAddress"
                                onChange={handleChange}
                                value={values.eventAddress}
                                variant="outlined"
                            />
                        </Grid>     
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                        <Link to="/files/Template_Invitados.xlsx" target="_blank" variant="inherit" color="primary" className="btn btn-primary" download>Descargar Template para invitados</Link>
                        </Grid>                                           
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <label htmlFor="upload-file">
                                <input
                                    style={{ display: "none" }}
                                    id="upload-file"
                                    name="upload-file"
                                    type="file"
                                    onChange={handleFileNameChange}
                                />
                                <Button component="span">
                                   2) Cargar archivo CSV con la lista de Invitados
                                </Button>{" "}
                            </label>
                            <Typography variant="h5">
                                {fileName}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        {textoSubmitButton}
                    </Button>

                    {renderBotonEnvioEmails()}
                </CardActions>
            </form>
            <br></br>
            {
                renderGrid()
            }
        </Card>
    );
};

EventosAdmin.propTypes = {
    className: PropTypes.string
};

export default EventosAdmin;
