import React, { Component, useState, useEffect } from 'react'
import MUIDataTable from "mui-datatables";


const DataGrid = props => {
    const columns = ["Nombre", "Apellidos", "Agencia/Empresa", "Email", "Celular", "Envio Invitacion", "Fecha Envio Invitacion", "Confirmacion Invitacion", "Fecha Confirmacion invitacion"];
    //const columns = ["Nombre", "Apellidos", "Agencia/Empresa", "Email", "Celular"];
    /*
    const columns = [
        { label: "Nombre", name: "nombre" },
        { label: "Apellido", name: "apellido" },
        { label: "Agencia/Empresa", name: "empresa" },
        { label: "Email", name: "email" },
        { label: "Celular", name: "celular" },
    ];
    */

    const options = {
        filter: true,
        filterType: "dropdown"
    };

    useEffect(() => {
        // Actualiza el título del documento usando la API del navegador
        console.log(props.data);
    });

    return (
        <div>
            <MUIDataTable
                title={"Lista de Invitados"}
                data={props.data}
                columns={columns}
                options={options}
            />
        </div>
    )

};


export default DataGrid;