import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import AddCircle from '@material-ui/icons/AddCircle';
import Group from '@material-ui/icons/Group'
import { withTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
//import siocketIOClient from 'socket.io-client';
import CountUp from 'react-countup';
import { initiateSocket, disconnectSocket, subscribeToChat, sendMessage } from './socket';
import { getEventById, postIn, postOut } from './actions'
import { green } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';

//const ENDPOINT ="";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  alignItemsAndJustifyContent: {
    width: 500,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'pink',
  },
}));

/*
const defaultProps = {
  m: 1,
  border: 1,
  style: { width: '40rem', height: '40rem' },
  alignItems: 'center',
  justifyContent: 'center',
};
*/

const defaultProps = {
  m: 1,
  border: 1,
  style: { width: '40vw', height: '45vh' },
  alignItems: 'center',
  justifyContent: 'center',
  display:"flex",
  //bgcolor:"lightgreen"
};


const EasyIn = props => {

  const { className, ...rest } = props;
  const classes = useStyles();
  

  const [eventName, setEventName] = useState("");
  const [eventCurrentOccupancy, setEventCurrentOccupancy] = useState(0);
  const [eventMaxCapacity, setEventMaxCapacity] = useState(0);
  const [eventOccupancyPercentage, setEventOccupancyPercentage] = useState(0);
  const [eventColor, setEventColor] = useState("#43a047");
  
  

  useEffect(() => {

    var arrayDeCadenas = window.location.pathname.split('/');
    var room = arrayDeCadenas[arrayDeCadenas.length-1];

    getEventById(room)
    .then(event=> {

      console.log(event.data);
      setEventName(event.data.name);
      setEventMaxCapacity(event.data.occupancy);
      setEventCurrentOccupancy(parseInt(event.data.attendeesin)-parseInt(event.data.attendeesout));
      let OccupancyPercentage = 100*(parseInt(event.data.attendeesin)-parseInt(event.data.attendeesout))/parseInt(event.data.occupancy)
      OccupancyPercentage = parseInt(OccupancyPercentage);
      setEventOccupancyPercentage(OccupancyPercentage);
      console.log(OccupancyPercentage);

      if (OccupancyPercentage <= 60 ) 
          setEventColor("#43a047");
      else
        if (OccupancyPercentage < 90)
            setEventColor("#e48b1f");
        else
            setEventColor("#ff0400");


      // "#43a047" Verde
      // #e48b1f Naranja
      // #ff0400 rojo

    })
    .catch(err=> {
      console.log(err);
    })
    

    if (room) initiateSocket(room);

    subscribeToChat((err, data) => {
      if(err) return;
      else {
          setEventName(data.name);
          setEventMaxCapacity(data.occupancy);
          setEventCurrentOccupancy(parseInt(data.attendeesin)-parseInt(data.attendeesout));
          let OccupancyPercentage = 100*(parseInt(data.attendeesin)-parseInt(data.attendeesout))/parseInt(data.occupancy);
          //setEventOccupancyPercentage(100*(parseInt(data.attendeesin)-parseInt(data.attendeesout))/parseInt(data.occupancy));
          OccupancyPercentage = parseInt(OccupancyPercentage);
          setEventOccupancyPercentage(OccupancyPercentage);
          console.log(OccupancyPercentage);

          if (OccupancyPercentage <= 60 ) 
              setEventColor("#43a047");
          else
            if (OccupancyPercentage < 90)
                setEventColor("#e48b1f");
            else
                setEventColor("#ff0400");
        }
    });
    return () => {
      disconnectSocket();
    }
  }, []);

  
  const onEntry = () => {
    //e.preventDefault();

    console.log("entry");
    var arrayDeCadenas = window.location.pathname.split('/');
    var event_id = arrayDeCadenas[arrayDeCadenas.length-1];

        postIn(event_id).then(attendee=>{
          sendMessage(event_id,"in");
        });
  }

  const onExit = () => {
    //e.preventDefault();
    var arrayDeCadenas = window.location.pathname.split('/');
    var event_id = arrayDeCadenas[arrayDeCadenas.length-1];

        postOut(event_id).then(attendee=>{
          sendMessage(event_id,"out");
        });
  }


return (
  <div
    {...rest}
    className={clsx(classes.root, className)}>
    <Box display="flex" justifyContent="center">
      <Box display="flex" justifyContent="center">
      <div className="biggerText">
      {eventName}
      </div>
      </Box>
    </Box>
    <Box display="flex" justifyContent="center">
      <Box borderColor="primary.main"  {...defaultProps} bgcolor={eventColor} >
      <div className="biggerTextWhite">
      <Button color="primary" variant="contained" size="large" startIcon={<AddCircle />} onClick={() => onEntry()}>Entrada</Button>|
      <Button color="default" variant="contained" size="large" startIcon={<RemoveCircle />} onClick={() => onExit()}>Salida</Button>
      </div>
      </Box>
      <Box display = "flex" borderColor="secondary.main"  {...defaultProps} bgcolor={eventColor} >
      <div className="mediumTextWhite">
       {eventCurrentOccupancy}/{eventMaxCapacity}
      </div>
      </Box>
      <Box display = "flex" borderColor="secondary.main"  {...defaultProps} bgcolor={eventColor} >
      <div className="biggerTextWhite">
        {eventOccupancyPercentage}%
      </div>
      </Box>      
    </Box>
</div>
);
};

EasyIn.propTypes = {
  className: PropTypes.string
};

export default EasyIn;
