import axios from "axios";


export const getEventById = (id) => {
  try {
    return axios.get("/api/eventos/"+id);
  } catch (error) {
    console.error(error)
  }
};

export const getPreInvitadosByEventId = (id) => {
  try {
    return axios.get("/api/eventos/invitados/"+id);
  } catch (error) {
    console.error(error)
  }
};


export const getInvitacion = (id) => {
  try {
    return axios.get("/api/eventos/invitados/"+id);
  } catch (error) {
    console.error(error)
  }
};

export const getConfirmationInvite = (event_id, invitado_id) => {
  try {
    return axios.get("/api/eventos/invitadoById/"+event_id+"/"+invitado_id);
  } catch (error) {
    console.error(error)
  }  
}