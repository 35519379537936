import axios from "axios";

// Post In and get Attende Details
export const postIn = (event_id, invitado_id) => {
  try {
    return axios.get("/api/eventos/"+event_id+"/in/"+invitado_id);
  } catch (error) {
    console.error(error)
  }
};

// Post Out and get Attende Details
export const postOut = (event_id, invitado_id) => {
    try {
      return axios.get("/api/eventos/"+event_id+"/out/"+invitado_id);
    } catch (error) {
      console.error(error)
    }
  };
  