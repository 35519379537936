import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
//import { useHistory } from 'react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button
} from '@material-ui/core';


import { getInitials } from 'helpers';
import axios from "axios";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersTable = props => {
  const { className, ...rest } = props;

  //console.log(className);
  //console.log(users);

  const classes = useStyles();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const GetData = async () => {
      const result = await axios.get("/api/eventos")
      setData(result.data);
    }

    GetData();
    //console.log(data);  
  }, []);

  /*
  const handleSelectAll = event => {
    const { users } = props;

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = users.map(user => user.id);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };
  */

  /*
  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };
  */

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const onRegisterIn = eventId => {
    //let history = useHistory()
    let path = "/in/" + eventId;
    //history.push(path);

    console.log(path);

    window.location.href = window.location.href + path;
  }

  const onRegisterOut = eventId => {
    //let history = useHistory()
    let path = "/out/" + eventId;
    //history.push(path);
    console.log(path);

    window.location.href = window.location.href + path;
  }

  const onRegisterInQR = eventId => {
    //let history = useHistory()
    let path = "/inqr/" + eventId;
    //history.push(path);

    console.log(path);

    window.location.href = window.location.href + path;
  }

  const onRegisterInQRExpositores = expositorId => {
    //let history = useHistory()
    let path = "/qr/" + expositorId;
    //history.push(path);

    console.log(path);

    window.location.href = window.location.href + path;
  }


  const onRegisterOutQR = eventId => {
    //let history = useHistory()
    let path = "/outqr/" + eventId;
    //history.push(path);
    console.log(path);

    window.location.href = window.location.href + path;
  }


  const onDashboard = eventId => {
    //let history = useHistory()

    let path = "/realtimedashboard/" + eventId;
    //history.push(path);
    //console.log(window.location.href+ path);

    console.log(path);

    window.location.href = window.location.href + path;
  }


  const onRegisterInOut = eventId => {
    //let history = useHistory()

    let path = "/inout/" + eventId;
    //history.push(path);
    //console.log(window.location.href+ path);

    window.location.href = window.location.href + path;
  }

  const onAttendance = eventId => {
    //let history = useHistory()

    let path = "/attendance/" + eventId;
    //history.push(path);
    //console.log(window.location.href+ path);

    window.location.href = window.location.href + path;
  }


  const onEdit = eventId => {
    let path = "/admin/" + eventId;
    window.location.href = window.location.href + path;
  }


  
  const onSelfRegistration = eventId => {
    let path = "/selfregistration/" + eventId;
    window.location.href = window.location.href + path;
  }

  const onSelfRegistrationLector = eventId => {
    let path = "/selfregistrationkeyboard/" + eventId;
    window.location.href = window.location.href + path;
  }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Evento</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Invitados Dentro</TableCell>
                  <TableCell>Capacidad Maxima</TableCell>

                  <TableCell></TableCell>
                  {/*
                  <TableCell></TableCell>
                  <TableCell></TableCell>

                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  */}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {/*
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.slice(0, rowsPerPage).map(event => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={event._id}
                    selected={selectedUsers.indexOf(event._id) !== -1}
                  >
                    <TableCell>{event.name}</TableCell>
                    <TableCell>
                      {new Date(event.eventDate).toISOString().slice(0, 10)}
                    </TableCell>
                    <TableCell>{event.attendeesin - event.attendeesout}</TableCell>
                    <TableCell>{event.occupancy}</TableCell>
                    <TableCell><Button color="primary" variant="contained" onClick={() => onEdit(event._id)}>Editar</Button></TableCell>
                    {/*
                    <TableCell><Button color="primary" variant="outlined" onClick={() => onSelfRegistration(event._id)}>Auto-Camara</Button></TableCell>
                    <TableCell><Button color="primary" variant="outlined" onClick={() => onSelfRegistrationLector(event._id)}>Auto-Lector</Button></TableCell>

                    <TableCell><Button color="primary" variant="outlined" onClick={() => onRegisterIn(event._id)}>Ingresos</Button></TableCell>   
                    <TableCell><Button color="primary" variant="outlined"  onClick={() => onRegisterOut(event._id)}>Salidas</Button></TableCell>   
                    */}
                    <TableCell><Button color="primary" variant="outlined" onClick={() => onRegisterInQRExpositores(event.expositorId)}>Ingresos QR</Button></TableCell>
                    <TableCell><Button color="primary" variant="contained" onClick={() => onAttendance(event._id)}>Asistencia</Button></TableCell>
                    {/*<TableCell><Button color="primary" variant="outlined" onClick={() => onRegisterOutQR(event._id)}>Salidas QR</Button></TableCell>
                    <TableCell><Button color="primary" variant="contained" onClick={() => onDashboard(event._id)}>Dashboard</Button></TableCell>*/}

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={data.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string
};

export default UsersTable;