import axios from "axios";


export const getEventByExpositorId = (id) => {
  try {
    return axios.get("/api/eventos/byexpositorId/"+id);
  } catch (error) {
    console.error(error)
  }
};

// Post In and get Attende Details
export const postIn = (event_id, invitado_id) => {
    /*
    try {
      return axios.get("/api/eventos/"+event_id+"/in/"+invitado_id);
    } catch (error) {
      console.error(error)
    }
    */
    return axios.get("/api/eventos/"+event_id+"/in4/"+invitado_id);
  };


  export const postNotes = (event_id, invitado_id, notes) => {
    try {
      return axios.post("/api/eventos/"+event_id+"/notas/"+invitado_id, notes);
    } catch (error) {
      console.error(error)
    } 
  }