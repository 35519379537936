import io from 'socket.io-client';
let socket;

export const initiateSocket = (room) => {
  socket = io();
  console.log(`Connecting socket...`);
  if (socket && room) socket.emit('join', room);
}

export const disconnectSocket = () => {
  console.log('Disconnecting socket...');
  if(socket) socket.disconnect();
}

export const subscribeToChat = (cb) => {
  if (!socket) return(true);
  socket.on('FromAPI', msg => {
    console.log('Websocket event received!');
    return cb(null, msg);
  });
}

/// it wont be required for our use case at this moment
export const sendMessage = (room, message) => {
  if (socket) socket.emit('chat', { message, room });
}

/*
    const socket = siocketIOClient(ENDPOINT);
    socket.on("FromAPI", data=> {
        setResponse(data);
    });
*/    