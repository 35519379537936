import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { postIn } from './actions'
import { getEventById } from '../RealTimeDashboard/actions'
import { Typography } from '@material-ui/core';
import { initiateSocket, disconnectSocket, sendMessage, subscribeToChat } from '../RealTimeDashboard/socket';
import QrReader from 'react-qr-reader';
import useWindowDimensions from '../hooks/useWindowDimensions';

const EntradaQR = props => {

  const { width } = useWindowDimensions();
  const { className, ...rest } = props;
  //const classes = useStyles();
  const [result, setResult] = useState("No result");
  const [attendeeName, setAttendeeName] = useState("Por favor, acerque el codigo QR dentro del cuadro rojo");
  const [event, setEvent] = useState({});

  const handleScan = data => {
    if (data) {
      if (data != result) {
        setResult(data);

        var arrayDeCadenas = window.location.pathname.split('/');
        var event_id = arrayDeCadenas[arrayDeCadenas.length - 1];

        
        //if (data.match(/^[0-9a-fA-F]{24}$/)) {
          var attendee_guid = data;

          postIn(event_id, attendee_guid).
            then(attendee => {
              if (attendee.data != null && attendee.data !== undefined) {
                let nombre = attendee.data.nombres + " " + attendee.data.apellidos;
                setAttendeeName("Bienvenido " + nombre + " 👏");
                sendMessage(event_id, "in");
              }
              else {
                setAttendeeName("El usuario no esta registrado en el evento 😔");
              }
            }).
            catch(err => {
              console.log(err);
               setAttendeeName("Error! no fue posible procesar la entrada 🛑");
            })
            /*
        } else {
          //Implement Logic to parse string from 2 vendors
          setAttendeeName("El QR no es valido 🛑");
        }*/
      }
    }
  }

  /*
  const handleScan = data => {
    if (data) {
      if (data != result) {
        setResult(data);

        var arrayDeCadenas = window.location.pathname.split('/');
        var event_id = arrayDeCadenas[arrayDeCadenas.length - 1];

        if (data.match(/^[0-9a-fA-F]{24}$/)) {
          var attendee_guid = data;

          postIn(event_id, attendee_guid).
            then(attendee => {
              if (attendee.data != null && attendee.data !== undefined) {
                let nombre = attendee.data.nombres + " " + attendee.data.apellidos;
                setAttendeeName("Bienvenido " + nombre + " 👏");
                sendMessage(event_id, "in");
              }
              else {
                setAttendeeName("El usuario no esta registrado en el evento 😔");
              }
            }).
            catch(err => {
              console.log(err);
            })
        } else {
          //Implement Logic to parse string from 2 vendors
          setAttendeeName("El QR no es valido 🛑");
        }
      }
    }
  }
  */

  const handleError = err => {
    console.error(err)
  }

  useEffect(() => {

    var arrayDeCadenas = window.location.pathname.split('/');
    var room = arrayDeCadenas[arrayDeCadenas.length - 1];

    getEventById(room)
      .then(event => {

        //console.log(event.data);
        setEvent(
          {
            name: event.data.name,
            maxOccupancy: event.data.occupancy,
            currentOccupancy: parseInt(event.data.attendeesin) - parseInt(event.data.attendeesout),
            occupancyPercentage: parseInt(100 * (parseInt(event.data.attendeesin) - parseInt(event.data.attendeesout)) / parseInt(event.data.occupancy)),
            colorEvent: "#43a047"
          });

        if (event.occupancyPercentage >= 90) {
          setEvent(event => ({
            ...event,
            colorEvent: "#ff0400"
          }));
        }
        else {
          if (event.occupancyPercentage >= 60) {
            setEvent(event => ({
              ...event,
              colorEvent: "#e48b1f"
            }));
          }
        }
      })
      .catch(err => {
        console.log(err);
      })

    if (room) initiateSocket(room);
 
        subscribeToChat((err, data) => {
          if (err) return;
          else {

            setEvent(
              {
                name: data.name,
                maxOccupancy: data.occupancy,
                currentOccupancy: parseInt(data.attendeesin) - parseInt(data.attendeesout),
                occupancyPercentage: parseInt(100 * (parseInt(data.attendeesin) - parseInt(data.attendeesout)) / parseInt(data.occupancy)),
                colorEvent: "#43a047"
              });
    
    
              if (event.occupancyPercentage >= 90) {
                setEvent(event => ({
                  ...event,
                  colorEvent: "#ff0400"
                }));
              }
              else {
                if (event.occupancyPercentage >= 60) {
                  setEvent(event => ({
                    ...event,
                    colorEvent: "#e48b1f"
                  }));
                }
              }
          }
        });

    return () => { disconnectSocket(); }
  }, []);


  const previewStyle = {
    width: (width < 768) ? '100%' : '45%'
  }

  return (
    <div>
      <br></br>
      <Typography variant="h1">
        Entrada
      </Typography>
      <br></br>
      <Typography variant="h2">
       {event.name} / {event.occupancyPercentage}%
      </Typography>
      <br></br>
      <Typography variant="h3">
        {attendeeName}
      </Typography>
      <br></br>
      <QrReader
        delay={200}
        onError={handleError}
        onScan={handleScan}
        style={previewStyle}
      />
    </div>
  );
};

EntradaQR.prototype = {
  className: PropTypes.string
};

export default EntradaQR;

