import React, { useState , useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import  AttendeesTable from './components';
import { Typography } from '@material-ui/core';
import { getEventById } from '../RealTimeDashboard/actions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const  AttendeeList = () => {
  const classes = useStyles();

  const[users] = "";
  const[eventName, setEventName] = useState("");

  
  useEffect(() => {

    var arrayDeCadenas = window.location.pathname.split('/');
    var eventId = arrayDeCadenas[arrayDeCadenas.length - 1];

    getEventById(eventId)
      .then(event => {

        setEventName(event.data.name);

      })
      .catch(err => {
        console.log(err);
      })
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>
      <br></br>
        <Typography variant="h2">
         Asistencia {eventName}
        </Typography>
        <br></br>
        <AttendeesTable users={users} />
      </div>
    </div>
  );
};

export default AttendeeList;