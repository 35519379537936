import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import { postIn } from './actions'
import { SearchInput } from 'components';
import { Typography } from '@material-ui/core';
import { initiateSocket, disconnectSocket, sendMessage } from '../RealTimeDashboard/socket';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },

  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const Registro = props => {

  const { className, ...rest } = props;
  const classes = useStyles();
  const [attendee, setAttendee] = useState("");
  const [attendeeName, setAttendeeName] = useState("");
  /*
    useEffect(() => {
    
      const timer = setTimeout(() => {
        console.log('This will run after 1 second!')
      }, 1000);
      return () => clearTimeout(timer);
    }, []);
    */

  useEffect(() => {

    var arrayDeCadenas = window.location.pathname.split('/');
    var room = arrayDeCadenas[arrayDeCadenas.length - 1];

    if (room) initiateSocket(room);
    /*
    subscribeToChat((err, data) => {
      if(err) return;
      else 
        setResponse(data);
    });
    */

    return () => { disconnectSocket(); }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    var arrayDeCadenas = window.location.pathname.split('/');
    var event_id = arrayDeCadenas[arrayDeCadenas.length - 1];

    if (attendee.match(/^[0-9a-fA-F]{24}$/)) {
      var attendee_guid = attendee;

      postIn(event_id, attendee_guid).
        then(attendee => {
          if (attendee.data != null && attendee.data !== undefined) {
            let nombre = attendee.data.nombres + " " + attendee.data.apellidos;
            setAttendeeName("Bienvenido " + nombre + " 👏");
            sendMessage(event_id, "in");
          }
          else {
            setAttendeeName("El usuario no esta registrado en el evento 😔");
          }
        }).
        catch(err => {
          console.log(err);
        })
    } else {
      //Implement Logic to parse string from 2 vendors
      setAttendeeName("El Codigo no es valido 🛑");
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <br></br>
        <Typography variant="h1">
          Entrada
        </Typography>
        <br></br>
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            placeholder="Buscar Invitado"
            value={attendee}
            onChange={e => setAttendee(e.target.value)}
          />
          <br></br>
          <Typography variant="h3">
            {attendeeName}
          </Typography>
        </div>

      </div>
    </form>
  );

};

Registro.propTypes = {
  className: PropTypes.string
};

export default Registro;
