import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { getEventById } from '../RealTimeDashboard/actions'
import { useSnackbar } from 'notistack';

import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Grid,
    Button,
    TextField,
    Typography
} from '@material-ui/core';
import { EventBusyOutlined } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    root: {}
}));

const InvitadoAdmin = props => {
    const { className, ...rest } = props;

    const classes = useStyles();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const history = useHistory();

    //const [selectedFiles, setSelectedFiles] = useState([]);
    //const [fileName, setFileName] = useState("");
    const [eventId, setEventId] = useState("");
    const [errores, setError] = useState("");
    //const [data, setData] = useState();
    //const [textoSubmitButton, setTextoSubmitButton] = useState("Crear Evento");
    const [evento, setEvento] = useState("");

    const [values, setValues] = useState({
        name: '',
        occupancy: 0,
        eventDate: '',
        eventTime: '',
        eventAddress: ''
    });


    useEffect(() => {
        var arrayDeCadenas = window.location.pathname.split('/');

        if (arrayDeCadenas.length > 3) {
            var eventId = arrayDeCadenas[arrayDeCadenas.length - 1];

            setEventId(eventId);

            getEventById(eventId)
                .then(event => {
                    setValues(
                        {
                            name: event.data.name,
                            occupancy: event.data.occupancy,
                            eventDate: new Date(event.data.eventDate).toISOString().slice(0, 10),
                            eventTime: event.data.eventTime,
                            eventAddress: event.data.eventAddress,
                        });

                            let eventoDesc = "Evento: " + event.data.name + " | Fecha: " + new Date(event.data.eventDate).toISOString().slice(0, 10) + " | Hora: " + event.data.eventTime + " | Direccion: " + event.data.eventAddress;
                            console.log(eventoDesc);
                    setEvento(eventoDesc);
                })
                .catch(err => {
                    console.log(err);
                    enqueueSnackbar("No fue posible cargar la informacion del evento", { variant: 'error' });
                })
        }
    }, [eventId]);

    const handleChange = event => {
        //console.log("change");
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const submitHandler = event => {

    };


    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
        <br>

        </br>
        <br>

        </br>
        <br>
        </br>
            <form
                autoComplete="off"
                onSubmit={submitHandler}
            >
                <CardHeader
                    subheader="Capture su informacion por favor para el registrarse al evento"
                    title={evento}
                />
                <Divider />
                <CardContent>
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                autoFocus
                                helperText="Por favor especifique su nombre"
                                label="Nombre"
                                margin="dense"
                                name="nombre"
                                onChange={handleChange}
                                required
                                value={values.nombre}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText="Por favor especifique su apellido"
                                label="Apellido"
                                margin="dense"
                                name="apellido"
                                onChange={handleChange}
                                required
                                value={values.apellido}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText="Por favor especifique su correo electronico"
                                label="correo electronico"
                                margin="dense"
                                name="email"
                                onChange={handleChange}
                                required
                                type="email"
                                value={values.email}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText="Por favor especifique su numero de celular"
                                label="celular"
                                margin="dense"
                                name="celular"
                                type="number"
                                onChange={handleChange}
                                required
                                value={values.celular}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText="Por favor especifique el Estado del pais de residencia"
                                label="Estado"
                                margin="dense"
                                name="estado"
                                onChange={handleChange}
                                required
                                value={values.estado}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText="Por favor especifique su ciudad de residencia"
                                label="Ciudad"
                                margin="dense"
                                name="ciudad"
                                onChange={handleChange}
                                required
                                value={values.ciudad}
                                variant="outlined"
                            />
                        </Grid>                        
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        REGISTRO
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
};

InvitadoAdmin.propTypes = {
    className: PropTypes.string
};

export default InvitadoAdmin;
