import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import AddCircle from '@material-ui/icons/AddCircle';
import Group from '@material-ui/icons/Group'
import { withTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
//import siocketIOClient from 'socket.io-client';
import CountUp from 'react-countup';
import { getConfirmationInvite } from '../RealTimeDashboard/actions'
import { green } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';

/*

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  alignItemsAndJustifyContent: {
    width: 500,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'pink',
  },
}));


const defaultProps = {
  m: 1,
  border: 1,
  style: { width: '40rem', height: '35rem' },
  alignItems: 'center',
  justifyContent: 'center',
  display: "flex"
};
*/
const Confirmation = props => {

  //const { className, ...rest } = props;
  //const classes = useStyles();

  const [values, setValues] = useState({
    nombre: '',
    apellido: '',
    evento: '',
    eventDate: '',
    eventTime: '',
    eventAddress: ''
  });


  const [message, setMessage] = useState("");
  const [evento, setEvento] = useState("");



  useEffect(() => {
    var arrayDeCadenas = window.location.pathname.split('/');

    console.log(arrayDeCadenas);

    if (arrayDeCadenas.length > 3) {
      var eventId = arrayDeCadenas[arrayDeCadenas.length - 2];
      var invitadoId = arrayDeCadenas[arrayDeCadenas.length - 1];


      getConfirmationInvite(eventId, invitadoId)
        .then(event => {

          console.log(event.data[0]);
          setValues(
            {
              nombre: event.data[0].nombre,
              apellido: event.data[0].apellido,
              evento: event.data[0].evento,
              eventDate: new Date(event.data[0].eventDate).toISOString().slice(0, 10),
              eventTime: event.data[0].eventTime,
              eventAddress: event.data[0].eventAddress,
            });

          setMessage("Hola " + event.data[0].nombre + ", tu asistencia quedo confirmada 👏");
          setEvento("Evento: " + event.data[0].evento + " | Fecha: " + new Date(event.data[0].eventDate).toISOString().slice(0, 10) + " | Hora: " + event.data[0].eventTime + " | Direccion: " + event.data[0].eventAddress);
        })
        .catch(err => {
          setMessage("Hola no fue posible confirmar tu asistencia, por favor, intenta mas tarde 😔");
          console.log(err);
        })
    }
  }, []);

  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Box display="flex" justifyContent="center">
        <div>
          <Typography variant="h1">
            {message}
          </Typography>
        </div>

      </Box>
      <br></br>
      <br></br>
      <Box display="flex" justifyContent="center">
        <div>
          <Typography variant="h3">
          {evento}
          </Typography>
        </div>
        <br></br>
      </Box>

    </div>
  );
};

/*
Confirmation.propTypes = {
  className: PropTypes.string
};

*/

export default Confirmation;
